// https://github.com/blueimp/jQuery-File-Upload

import './jquery' // uploader isn't working without jquery required here

import 'jquery-ui/ui/widget' // use a current version of jquery-ui widget

// NOTE: Removing `script-loader` will cause errors! Need to investigate
// how to resolve this issue.
require('script-loader!blueimp-tmpl/js/tmpl.js')
require('script-loader!blueimp-load-image/js/load-image.all.min.js')
require('script-loader!blueimp-canvas-to-blob/js/canvas-to-blob.js')

require('script-loader!blueimp-file-upload/js/jquery.iframe-transport.js')
require('script-loader!blueimp-file-upload/js/jquery.fileupload.js')
require('script-loader!blueimp-file-upload/js/jquery.fileupload-process.js')
require('script-loader!blueimp-file-upload/js/jquery.fileupload-image.js')
require('script-loader!blueimp-file-upload/js/jquery.fileupload-audio.js')
require('script-loader!blueimp-file-upload/js/jquery.fileupload-video.js')
require('script-loader!blueimp-file-upload/js/jquery.fileupload-validate.js')
require('script-loader!blueimp-file-upload/js/jquery.fileupload-ui.js')

import 'blueimp-file-upload/css/jquery.fileupload.css'
