// Converts jQuery 1.x/2.x style `.load()` calls to jQuery 3.x `.on("load",...)`
// `.load()` was removed in 3.x
jQuery.fn.load = function () {
  var args = Array.prototype.slice.call(arguments)
  var callback = args.slice(-1)[0]

  if (args.length > 1) {
    var eventData = args[0]
    $(window).on('load', null, eventData, callback)
  } else {
    $(window).on('load', callback)
  }
}
